@import 'theme/variables';

.ag-theme-bootstrap {
  .ag-root {
    border: none !important;
    font-family: EYInterstateBold;
  }

  .ag-body-viewport {
    overflow-x: hidden;
  }

  .ag-bl-center .ag-bl-overlay {
    pointer-events: auto;
  }

  .ag-header-icon {
    margin: 2px 0 0 5px !important;
  }

  .ag-icon {
    height: 12px !important;
    background-repeat: no-repeat;
    background-position: center;
    width: 12px;

    &::before {
      content: none;
    }
  }

  .ag-icon-asc {
    background-image: url('/assets/images/arrow_down.svg') !important;
  }

  .ag-icon-desc {
    background-image: url('/assets/images/arrow_up.svg') !important;
  }

  .overlay-no-rows {
    font-size: 3.5rem;
    font-family: EYInterstateBold;
    color: #95989a;
  }

  .ag-overlay-loading-wrapper {
    background: linear-gradient(to bottom, rgb(0 0 0 / 0%) 0%, rgb(0 0 0 / 100%) 100%);
    //background: rgba(0, 0, 0, 0.6);
  }

  .ag-header,
  .ag-body-viewport {
    background-color: #333;
  }

  .ag-header-viewport {
    border-bottom: 1px solid grey !important;
  }

  .ag-header-row {
    color: white !important;

    .ag-header-cell-label {
      align-items: self-start;
    }
  }

  .ag-header-cell {
    font-family: EYInterstateBold;
  }

  .ag-row {
    background: #333;
    color: white !important;

    &.resolved-row {
      color: #777 !important;
    }

    &:hover {
      background: #3e3e3e;
    }
  }

  .ag-row-selected {
    background: rgb(255 255 255 / 15%);

    &:hover {
      background: rgb(255 255 255 / 20%);
    }
  }

  .ag-header-cell,
  .ag-cell {
    user-select: text !important;
    margin-top: 1rem !important;

    &.handled-cell-resolved,
    &.handled-cell-resolve {
      cursor: pointer;
    }

    &.handled-cell-resolve {
      color: $cianBlue;
    }
  }

  .ag-cell-focus {
    border: none !important;
  }
}

.col-grey-color {
  color: $mediumGrey;
}

.edit-cell,
.delete-cell {
  padding-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  float: left;

  img {
    width: 2rem;
    height: 2rem;
    cursor: pointer;

    &.hover-icon {
      display: none;
    }
  }

  &:hover {
    img.hover-icon {
      display: inline-block;
    }

    img.normal-icon {
      display: none;
    }
  }
}

.info-cell {
  .info-icon {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.edit-cell {
  img {
    padding: 5px;
  }
}

.ban-btn {
  width: calculate-rem(70px);
  height: calculate-rem(22px);

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  float: left;
  border-radius: 20px;

  border: 1px solid $solidGrey;

  &.ban {
    color: $cianBlue;

    &:hover {
      border-color: $cianBlue;
    }
  }

  &.enable {
    color: $yellow;

    &:hover {
      border-color: $yellow;
    }
  }
}
