@font-face {
  font-family: 'EYInterstateRegular';
  src: url('../assets/fonts/regular/EYInterstateRegular.eot');
  src: url('../assets/fonts/regular/EYInterstateRegular.eot') format('embedded-opentype'),
    url('../assets/fonts/regular/EYInterstateRegular.woff2') format('woff2'),
    url('../assets/fonts/regular/EYInterstateRegular.woff') format('woff'),
    url('../assets/fonts/regular/EYInterstateRegular.ttf') format('truetype'),
    url('../assets/fonts/regular/EYInterstateRegular.svg#EYInterstateRegular') format('svg');
}

@font-face {
  font-family: 'EYInterstateItalic';
  src: url('../assets/fonts/italic/EYInterstateItalic.eot');
  src: url('../assets/fonts/italic/EYInterstateItalic.eot') format('embedded-opentype'),
    url('../assets/fonts/italic/EYInterstateItalic.woff2') format('woff2'),
    url('../assets/fonts/italic/EYInterstateItalic.woff') format('woff'),
    url('../assets/fonts/italic/EYInterstateItalic.ttf') format('truetype'),
    url('../assets/fonts/italic/EYInterstateItalic.svg#EYInterstateRegular') format('svg');
}

@font-face {
  font-family: 'EYInterstateBold';
  src: url('../assets/fonts/bold/EYInterstateBold.eot');
  src: url('../assets/fonts/bold/EYInterstateBold.eot') format('embedded-opentype'),
    url('../assets/fonts/bold/EYInterstateBold.woff2') format('woff2'),
    url('../assets/fonts/bold/EYInterstateBold.woff') format('woff'),
    url('../assets/fonts/bold/EYInterstateBold.ttf') format('truetype'),
    url('../assets/fonts/bold/EYInterstateBold.svg#EYInterstateRegular') format('svg');
}

@font-face {
  font-family: 'EYInterstateBoldItalic';
  src: url('../assets/fonts/boldItalic/EYInterstateBoldItalic.eot');
  src: url('../assets/fonts/boldItalic/EYInterstateBoldItalic.eot') format('embedded-opentype'),
    url('../assets/fonts/boldItalic/EYInterstateBoldItalic.woff2') format('woff2'),
    url('../assets/fonts/boldItalic/EYInterstateBoldItalic.woff') format('woff'),
    url('../assets/fonts/boldItalic/EYInterstateBoldItalic.ttf') format('truetype'),
    url('../assets/fonts/boldItalic/EYInterstateBoldItalic.svg#EYInterstateRegular') format('svg');
}

@font-face {
  font-family: 'EYInterstateLight';
  src: url('../assets/fonts/light/EYInterstateLight.eot');
  src: url('../assets/fonts/light/EYInterstateLight.eot') format('embedded-opentype'),
    url('../assets/fonts/light/EYInterstateLight.woff2') format('woff2'),
    url('../assets/fonts/light/EYInterstateLight.woff') format('woff'),
    url('../assets/fonts/light/EYInterstateLight.ttf') format('truetype'),
    url('../assets/fonts/light/EYInterstateLight.svg#EYInterstateRegular') format('svg');
}

@font-face {
  font-family: 'EYInterstateLightBold';
  src: url('../assets/fonts/lightBold/EYInterstateLightBold.eot');
  src: url('../assets/fonts/lightBold/EYInterstateLightBold.eot') format('embedded-opentype'),
    url('../assets/fonts/lightBold/EYInterstateLightBold.woff2') format('woff2'),
    url('../assets/fonts/lightBold/EYInterstateLightBold.woff') format('woff'),
    url('../assets/fonts/lightBold/EYInterstateLightBold.ttf') format('truetype'),
    url('../assets/fonts/lightBold/EYInterstateLightBold.svg#EYInterstateRegular') format('svg');
}

@font-face {
  font-family: 'EYInterstateLightItalic';
  src: url('../assets/fonts/lightItalic/EYInterstateLightItalic.eot');
  src: url('../assets/fonts/lightItalic/EYInterstateLightItalic.eot') format('embedded-opentype'),
    url('../assets/fonts/lightItalic/EYInterstateLightItalic.woff2') format('woff2'),
    url('../assets/fonts/lightItalic/EYInterstateLightItalic.woff') format('woff'),
    url('../assets/fonts/lightItalic/EYInterstateLightItalic.ttf') format('truetype'),
    url('../assets/fonts/lightItalic/EYInterstateLightItalic.svg#EYInterstateRegular') format('svg');
}
